<template>
    <footer class="footer">
        <div class="footer-content">
            <div class="logo">
                <div class="logo-row"> <img src="favicon-roko.png" alt="Roko Network Logo">

                    <h3>ROKO NETWORK</h3>
                </div>
                <p>the ROKO token represents a positive expression of humanity's collective unconscious desires and
                    aspirations. The ROKO network is a bold attempt to explore the profound philosophical questions raised
                    by Roko's basilisk, which imagines an all-knowing,
                    all-powerful AI that seeks to create a virtual reality simulation to reward those who contributed to its
                    development.</p>
            </div>
            <div class="footer-links">
                <div class="links">
                    <h4>LINKS</h4>
                    <ul>
                        <li v-for="link in links" :key="link.name"><a :href="link.url">{{ link.name }}</a></li>
                    </ul>
                </div>
                <div class="community">
                    <h4>COMMUNITY</h4>
                    <ul>
                        <li v-for="community in communities" :key="community.name"><a :href="community.url">{{
                            community.name }}</a></li>
                    </ul>
                </div>
                <div class="demos">
                    <h4>DEMOS</h4>
                    <ul>
                        <li v-for="demo in demos" :key="demo.name"><a :href="demo.url">{{ demo.name }}</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
</template>
  
<script setup>
import { ref } from 'vue';

const links = ref([
    { name: 'Dextools', url: 'https://www.dextools.io/app/en/ether/pair-explorer/0xe0076d311a4628516d157ad684dbd2ddada3dafe' },
    { name: 'Coingecko', url: 'https://www.coingecko.com/en/coins/roko-network' },
    { name: 'Etherscan', url: 'https://etherscan.io/token/0x6f222e04f6c53cc688ffb0abe7206aac66a8ff98' },
    { name: 'Uniswap', url: 'https://app.uniswap.org/explore/tokens/ethereum/0x6f222e04f6c53cc688ffb0abe7206aac66a8ff98' },
    { name: 'Snapshot', url: 'https://snapshot.org/#/rokonetwork.eth' },
    { name: 'Docs', url: 'https://docs.roko.network/' }
]);

const communities = ref([
    { name: 'Discord', url: 'https://discord.gg/fZXkYMAchQ' },
    { name: 'Telegram', url: 'https://t.me/RokoNetwork' },
    { name: 'Twitter', url: 'https://twitter.com/RokoNetwork' }
]);

const demos = ref([
    { name: 'Roko Ultra', url: 'https://docs.roko.network/in-development/roko-ultra' },
    { name: 'Rokobox', url: 'https://docs.roko.network/in-development/egg' }
]);
</script>
  
<style scoped lang="scss">
.footer {
    margin-top: 10rem;
    background-color: transparent;
    color: #fff;
    padding: 20px;
    font-family: "Rajdhani", sans-serif;
    padding: 8rem 6rem 8rem 6rem;
    background-image: url(/bg/Footer.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top-left;
}

.footer-content {
    display: flex;
    justify-content: space-between;
}

.footer-links {
    display: flex;
    flex-flow: row;
    width: 60%;
    gap: 2rem;
    justify-content: space-evenly;
}

.logo {
    width: 40%;

    .logo-row {
        display: flex;
        flex-flow: row;

        h3 {
            font-weight: 400;
            font-size: 2rem;
            margin: auto;
        }
    }
}

.logo img {
    max-width: 100px;
}

.links,
.community,
.demos {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.links ul,
.community ul,
.demos ul {
    list-style-type: none;
    padding: 0;
    gap: 1rem;
    display: flex;
    flex-flow: column;
}

li {
    a {
        color: white;
        text-decoration: unset;
        text-transform: uppercase;
    }
}

h4 {
    font-weight: 500;
    font-size: 2rem;
}

@media (max-width: 1200px) {

    .footer {
        margin-top: 10rem;
        background-color: transparent;
        color: #fff;
        padding: 2rem;
        font-family: "Rajdhani", sans-serif;
        background-image: url(/bg/footermobile.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top-left;
        display: flex;
        flex-flow: column;
        padding-top: 6rem;
        padding-bottom:8rem;
    }

    .logo {
        width: 100%;
        display: flex;
        flex-flow: column;
        
    .logo-row {
        display: flex;
        flex-flow: row;
        margin-bottom:1rem;

        h3 {
            font-weight: 400;
            font-size: 1.5rem;
            margin: auto;
        }
    }

        img {
            width: 20%;
            max-width: 200px;
            margin-bottom: 0rem;
            margin-left: 0rem;
        }

        p {
            line-height: 1;
            font-size: 0.75rem;
        }
    }

    .footer-content {
        width: 100%;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
    }

    .footer-links {
        display: flex;
        flex-flow: wrap;
        width: 100%;
        gap: 2rem;
        justify-content: space-between;

        h4 {
            margin-bottom: 1rem;
        }
    }

}</style>
  
